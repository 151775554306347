/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
module.exports = {
  colors: {
    primary: '#36a6c1',
    secondary: '#00efc2',
    tertiary: '#f4643f',
    dark: '#161d1b',
    light: '#f5f8f6',
    warning: '#F4CA12',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#f6fbfd',
    background2: '#e5f3f9',
    background3: '#c6ffef',
    background4: '#e7fff9',
    background5: '#fef3ee',
    background6: '#fee3d6',
  },
  gradients: {
    primary: 'linear-gradient(to right, #5BBED5, #2687A3)',
    secondary: 'linear-gradient(to right, #00BE9A, #00EFC2)',
    tertiary: 'linear-gradient(to right, #F4643F, #E22714)',
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: '\'Rubik\', sans-serif',
      lg: {
        weight: 500,
      },
      md: {
        weight: 500,
      },
    },
    subheadings: {
      family: '\'Rubik\', sans-serif',
      lg: {
        weight: 500,
      },
      md: {
        weight: 500,
      },
    },
    body: {
      family: '\'Rubik\', sans-serif',
      lg: {
        weight: 400,
      },
      md: {
        weight: 400,
      },
    },
  },
  components: {
    Button: {
      rounded: 'max',
      variants: {
        primary: {
          color: 'white',
        },
      },
    },
    PackageCard: {
      border: '1px solid #738e84',
      backgroundColor: '#fff',
      stroke: {
        width: '1px',
        style: 'solid',
        color: '#738e84',
      },
      radius: '12px',
      label: {
        inside: true,
        backgroundColor: '#f4643f',
        radius: '24px',
        color: 'white',
      },
    },
  },
  shadow: 'none',
  radius: '32px',
}
