import React from 'react'

import { ThemeProvider } from '@leshen/ui'
import GlobalStyles from './CustomStyles/GlobalStyles'
import theme from 'gatsby-theme-movearoo/theme'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

Wrapper.defaultProps = {
  theme: {},
}

export default Wrapper
